import React from "react";
import { stackList } from "../../data/ProjectData";
import {
  Image,
  Technologies,
  Tech,
  TechImg,
  TechName,
  ContactWrapper,
} from "./AboutElements";
function About() {
  return (
    <>
  <div id="about">
    <br></br>
      <h2  class="hr-lines"> 
      <p style={{fontSize: '30px'}}>ABOUT ME</p>
      </h2>

    <ContactWrapper >
      <div className="Container">
        <div className="BigCard">
          <Image
            src="https://raw.githubusercontent.com/dianashi/personal-website/main/assets/DS.png"
            alt="man-svgrepo"
          />
          <div className="AboutBio">
          Hello! My name is <strong>Diana (Yiran) Shi</strong>, and I am a student, a programmer, a piano player, and a cat lover. 
          Currently, I am a fourth-year computer science student at the <strong>University of Waterloo</strong>. 
          I love learning and trying new things! 
          I am a Software Engineer Intern at <strong>DoorDash</strong> and I have previous work experience as a Full Stack Web Developer Intern at <strong>CI Technologies</strong>, Software Engineer Intern at <strong>UKG</strong>, and <strong>Ford Motor Company</strong>.
          I am currently seeking a software internship for fall 2024.
          </div>
          <div className="AboutBio tagline2">
            I have become confident using the following technologies.
          </div>
          <Technologies>
            {stackList.map((stack, index) => (
              <Tech key={index} className="tech">
                <TechImg src={stack.img} alt={stack.name} />
                <TechName>{stack.name}</TechName>
              </Tech>
            ))}
          </Technologies>
        </div>
      </div>
    </ContactWrapper>
    </div>
    </>
  );
}

export default About;
